<template>
<section>
    <div class="container">
        <div class="row mb-4 pb-4">
            <div class="col-12">
                <h1>Notas Metodológicas</h1>
                <div class="ip_separator bottom"></div>
                <h3 class="mt-4">Sobre la información de los ejes</h3>
                <p>Los datos estadísticos y sobre gasto en respuesta al VIH fueron levantados en las plataformas <a href="http://aidsinfo.unaids.org/" class="od_link">AIDSinfo</a> y el <a href="https://hivfinancial.unaids.org/hivfinancialdashboards.html">Tablero Financiero de VIH de ONUSIDA</a>. Los datos sobre contrataciones públicas en respuesta al VIH fueron levantados en los sitios web de contrataciones abiertas o de transparencia de cada país.</p>
                <div class="ip_separator bottom"></div>
                <h3 class="mt-4">Sobre la información de los contratos</h3>
                <p class="mt-2">Los contratos muestran información básica a través de variables principales de un contrato para su consulta y descarga:</p>
                <ul>
                    <li><strong>Año.</strong> Esta columna define el año del valor para el contrato. Es numérico y expresado en 4 dígitos.</li>
                    <li><strong>Título.</strong> Este es el nombre o breve descripción del contrato..</li>
                    <li><strong>Proveedor.</strong> El nombre o nombres de los proveedores del contrato..</li>
                    <li><strong>Fecha de contrato.</strong> Esta es la fecha en que se firmó (o publicó) el contrato.</li>
                    <li><strong>Institución.</strong> El nombre o nombres de las instituciones encargadas decontratar.</li>
                    <li><strong>Monto.</strong> La moneda se define de acuerdo al <a href="https://es.wikipedia.org/wiki/ISO_4217" class="od_link">estándar internacional ISO 4217</a>.</li>
                    <li><strong>Artículos.</strong> La lista de servicios o productos de los que refiere el contrato.</li>
                </ul>
                <p>La información de los contratos se obtiene de los siguientes sitios:</p>
                <ul>
                    <li><h4>Colombia</h4>
                        <a href="https://colombiacompra.gov.co/transparencia/api" class="od_link">Colombia Compra Eficiente (CCE)</a>
                    </li>
                    <li><h4>Ecuador</h4>
                        <a href="https://portal.compraspublicas.gob.ec/sercop/datos_abiertos/" class="od_link">Datos Abiertos – Servicio Nacional de Contratación Pública de Ecuador</a>
                    </li>
                    <li><h4>El Salvador</h4>
                        <a href="https://www.transparencia.gob.sv/api/v1/home" class="od_link">Portal de Transparencia - El Salvador</a>
                    </li>
                    <li><h4>Guatemala</h4>
                        <a href="https://www.guatecompras.gt/" class="od_link">Guatecompras</a>
                    </li>
                    <li><h4>Honduras</h4>
                        <a href="http://www.contratacionesabiertas.gob.hn/" class="od_link">Portal de Contrataciones Abiertas de Honduras</a>
                    </li>
                    <li><h4>Paraguay</h4>
                        <a href="https://www.contrataciones.gov.py/datos/data" class="od_link">Datos Abiertos DNCP - Contrataciones Públicas de Paraguay</a>
                    </li>                    
                </ul>
                <p class="mt-2">Los contratos se obtienen de manera autómatica mensualmente para todos los países con excepción de Guatemala, donde debido a la complejidad del sitio no es posible automatizar este proceso, sin embargo en este caso se cuenta con 815 contratos de 2010 a 2020, relacionados con el gasto en VIH.</p>
                <p>Para el caso de Colombia, Ecuador, Honduras y Paraguay, los datos se encuentran en el <a href="https://standard.open-contracting.org/latest/es/" class="od_link">Estándar de Contrataciones Abiertas</a> (OCDS, por sus siglas en inglés) y se obtienen mediante el uso de las APIs disponibles 
                    en cada uno de los países.
                </p>

            </div>
        </div>
        
    </div>
    </section>
</template>